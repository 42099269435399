import { Nav } from "../component/nav";
import { Footer } from "../component/footer";
import { ProductCard } from "../component/product_card";
import axios from "axios";
import { start_url_fetch } from "../module/settings";
import { useEffect, useState } from "react";
import { init_dataLayer } from "../module/dataLayer";

export const Like = () => {
  const email = document.cookie.split("email").length - 1 === 0 ? "" : "ok";
  if (email === "") {
    window.location = "/user";
  }

  const [lst_product, set_lst_product] = useState([]);

  useEffect(() => {
    axios(
      start_url_fetch +
        "/get_like?email=" +
        document.cookie.split("email=")[1].split(",")[0] +
        "&password=" +
        document.cookie.split("password=")[1].split(";")[0]
    ).then((r) => {
      let data = [];
      for (let i = 0; i < r.data.split(";").length - 1; i++) {
        data.push([r.data.split(";")[i].split(",")]);
      }
      console.log(data);
      if (data.length === 0) window.location = "/search";
      set_lst_product(data);
    });
  }, []);

  return (
    <div>
      <Nav />
      <div className={"container-product-search like"}>
        {lst_product.map((a) => (
          <ProductCard lst={a} />
        ))}
      </div>
      <Footer />
    </div>
  );
};
