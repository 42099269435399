import { button_click_data_layer } from "../module/dataLayer";

export const BannerCookie = () => {
  if (document.cookie.split("accept").length - 1 !== 0) return;
  return (
    <div className={"container-cookie"}>
      <img
        src={
          "https://backtocookies.com/wp-content/uploads/2021/05/backtocookies-icon-cookie-croque.png"
        }
        alt={"coockie"}
      />
      <h2>Cookie Policy</h2>
      <h4>
        Your private data will be sold to anonymous third parties and further in
        life they'll contact you offering services you never asked for.
      </h4>
      <div
        onClick={() => {
          document.querySelector(".container-cookie").classList.add("active");
          document.cookie = "accept=1";
          button_click_data_layer(["cookie", "body"]);
        }}
        className={"accept"}
      >
        Accept
      </div>
    </div>
  );
};
