export const lst_possibility= [
               [
                   [
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113357926514556948/white_vierge_teef.png?width=741&height=741)',
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113357925184962600/purple_vierge_teef.png?width=741&height=741)',
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113357923117170838/green_vierge_teef.png?width=741&height=741)',
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113357924220280872/blue_vierge_teef.png?width=741&height=741)'
                   ],
                   [
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113357727180279888/white_vierge_teed.png?width=741&height=741)',
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113357723778678784/pink_vierge_teed.png?width=741&height=741)',
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113357726228152431/green_vierge_teed.png?width=741&height=741)',
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113357724588199966/blue_vierge_teed.png?width=741&height=741)'
                   ]
               ],
               [
                   [
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113357611748831313/blue_fhoodie_vierge.png?width=741&height=741)',
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113357612977770536/purple_fhoodie_vierge.png?width=741&height=741)',
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113357610930946128/green_fhoodie_vierge.png?width=741&height=741)',
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113357611748831313/blue_fhoodie_vierge.png?width=741&height=741)'
                   ],
                   [
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113357434967310387/white_dhoodie_vierge.png?width=741&height=741)',
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113357438712819814/purple_dhoodie_vierge.png?width=741&height=741)',
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113357437043478579/green_dhoodie_vierge.png?width=741&height=741)',
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113357435965550632/blue_dhoodie_vierge.png?width=741&height=741)'
                   ]
               ],
               [
                   [
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113357998862127174/white_cap_vierge.png?width=1112&height=741)',
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113358000640512061/purple_cap_vierge.png?width=1112&height=741)',
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113357999495454790/green_cap_vierge.png?width=1112&height=741)',
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113358000082653226/blue_cap_vierge.png?width=1112&height=741)'
                   ],
                   [
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113357998862127174/white_cap_vierge.png?width=1112&height=741)',
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113358000640512061/purple_cap_vierge.png?width=1112&height=741)',
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113357999495454790/green_cap_vierge.png?width=1112&height=741)',
                       'url(https://media.discordapp.net/attachments/1113357364536541204/1113358000082653226/blue_cap_vierge.png?width=1112&height=741)'
                   ]
               ]
           ];
