import { useEffect, useState } from "react";
import { Button } from "./button";
import { lst_possibility } from "../module/product";

class Drag {
  constructor(ele1, ele2) {
    this.pos1 = 0;
    this.pos2 = 0;
    this.pos3 = 0;
    this.pos4 = 0;
    this.ele1 = ele1;
    this.ele2 = ele2;
    if (document.getElementById(this.ele1.id + "header")) {
      document.getElementById(this.ele1.id + "header").onmousedown =
        this.drag_mouse_down.bind(this);
    } else {
      this.ele2.onmousedown = this.drag_mouse_down.bind(this);
    }
  }

  drag_mouse_down(e) {
    e.preventDefault();
    this.pos3 = e.clientX;
    this.pos4 = e.clientY;
    document.onmouseup = this.close_drag_element.bind(this);
    document.onmousemove = this.element_drag.bind(this); //
  }

  element_drag(e) {
    e.preventDefault();
    this.pos1 = this.pos3 - e.clientX;
    this.pos2 = this.pos4 - e.clientY;
    this.pos3 = e.clientX;
    this.pos4 = e.clientY;
    this.ele1.style.top = this.ele1.offsetTop - this.pos2 + "px";
    this.ele1.style.left = this.ele1.offsetLeft - this.pos1 + "px";
  }

  close_drag_element() {
    document.onmouseup = null;
    document.onmousemove = null;
  }
}

function drop() {
  const drop_aera = document.querySelector(".design");
  drop_aera.addEventListener("dragover", (e) => {
    e.preventDefault();
    drop_aera.classList.add("hover");
  });

  drop_aera.addEventListener("dragleave", () => {
    drop_aera.classList.remove("hover");
  });

  drop_aera.addEventListener("drop", (e) => {
    e.preventDefault();

    const image = e.dataTransfer.files[0];
    const type = image.type;

    if (type == "image/png") {
      let reader = new FileReader();
      reader.readAsDataURL(image);
      let new_img = document.createElement("img");
      const file = new File([image], image.name, { type: image.type });
      const formData = new FormData();
      formData.append("picture", file);
      reader.onload = () => {
        fetch("https://victordalet.lescigales.org/dl.php", {
          method: "POST",
          body: formData,
        })
          .then((r) => r.text())
          .then((r) => (document.nb = r));
        const div = document.querySelector(".img-drag");
        new_img.src = reader.result;
        div.innerHTML = "";
        div.appendChild(new_img);
        document.querySelector(".img-drag img").style.width =
          document.getElementById("size-img").value.toString() + "%";
      };
    }
  });
}

export const Design = () => {
  const [type_product, set_type_product] = useState(0);
  const [return_product, set_return_product] = useState(0);
  const [color_product, set_color_product] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      drop();
      new Drag(
        document.querySelector(".img-drag"),
        document.querySelector(".img-drag")
      );
    }, 500);
  }, []);

  const lst_cat = [
    "url(https://media.discordapp.net/attachments/1113357364536541204/1113357727180279888/white_vierge_teed.png?width=741&height=741)",
    "url(https://media.discordapp.net/attachments/1113357364536541204/1113357614072479764/white_fhoodie_vierge.png?width=741&height=741)",
    "url(https://media.discordapp.net/attachments/1113357364536541204/1113357998862127174/white_cap_vierge.png?width=1112&height=741)",
  ];

  const lst_color = ["#B3CAC2", "#E2B0E6", "#B0E6B9", "#CFE8EC"];

  return (
    <div
      className={"design"}
      style={{
        "--url": lst_possibility[type_product][return_product][color_product],
      }}
    >
      <div className={"valid"}>
        <Button
          str={"Valid"}
          cmd={() => {
            document.getElementById("create-product.js").value = "This Product";
            document.querySelector(".container-add").classList.add("active");

            const actual_w = window.innerWidth;
            const actual_h = window.innerHeight;
            const last_w = 1669;
            const last_h = 965;

            let x = document
              .querySelector(".img-drag")
              .style.left.split("px")[0];
            let y = document
              .querySelector(".img-drag")
              .style.top.split("px")[0];
            let size = document.getElementById("size-img").value;

            x =
              (x * actual_h * actual_w * (120 * last_h * last_w)) /
              (783 * last_h * last_w) /
              (actual_w * actual_w);
            y =
              ((y * actual_h * actual_w * (130 * last_h * last_w)) /
                (372 * last_h * last_w) /
                (actual_w * actual_w)) *
              1.5;
            size =
              ((size * actual_h * actual_w * (50 * last_h * last_w)) /
                (58 * last_h * last_w) /
                (actual_w * actual_w)) *
              2;

            const url =
              "&picture=t" +
              "&cat=" +
              type_product +
              "&size=" +
              size +
              "&x=" +
              x +
              "&y=" +
              y +
              "&color=" +
              color_product;
            document.url = url;
          }}
        />
      </div>
      <div className={"img-drag"}></div>
      <div className={"right-bar"}>
        {lst_cat.map((a, i) => (
          <div
            onClick={() => {
              set_type_product(i);
              if (i === 2) set_return_product(0);
            }}
            style={{ "--url": a }}
            className={"img"}
          ></div>
        ))}
      </div>
      <div className={"tool-bar"}>
        <input
          type={"range"}
          id={"size-img"}
          min={1}
          max={200}
          onChange={() => {
            try {
              document.querySelector(".img-drag img").style.width =
                document.getElementById("size-img").value.toString() + "%";
            } catch (e) {
              console.log();
            }
          }}
        />
        <div className={"container-color"}>
          {lst_color.map((a, i) => (
            <div
              className={"color"}
              onClick={() => {
                set_color_product(i);
              }}
              style={{ "--clr": a }}
            ></div>
          ))}
        </div>
        <i
          className="fa-solid fa-rotate-left"
          onClick={() => {
            if (type_product !== 2) {
              if (return_product === 0) {
                set_return_product(1);
                document.querySelector(".img-drag").style.display = "none";
              } else {
                set_return_product(0);
                document.querySelector(".img-drag").style.display = "block";
              }
            }
          }}
        ></i>
      </div>
    </div>
  );
};
