import { Nav } from "../component/nav";
import { Button } from "../component/button";
import { ProductCard } from "../component/product_card";
import { Footer } from "../component/footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { start_url_fetch } from "../module/settings";
import { Load } from "../component/Load";
import { BannerCookie } from "../component/coockie";
import { init_dataLayer, view_item_list } from "../module/dataLayer";

export const Home = () => {
  const [lst_favourites, set_lst_favourites] = useState([]);

  useEffect(() => {
    init_dataLayer(["homepage", "homepage", "homepage", "", ""]);

    axios(start_url_fetch + "/get_product_fav").then((r) => {
      let data = [];
      for (let i = 0; i < r.data.split(";").length - 1; i++) {
        data.push([r.data.split(";")[i].split(",")]);
      }
      view_item_list(data, "LES FAVORIS DU MOMENTS");
      set_lst_favourites(data);
    });
  }, []);

  const lst_big_div = [
    ["Les petits cadeaux", "T-shirts à - de 50€"],
    ["Les nouveautés", "Découvrir"],
  ];

  return (
    <div>
      <Load />
      <BannerCookie />
      <Nav></Nav>
      <div className={"home-container"}>
        <div className={"left-container"}>
          <div className={"title"}>WELCOME AT STREET LINKERS</div>
          <div className={"description"}>
            Find all our most beautiful personalized clothes from street
            linkers. Finally when we talk about clothing we are obviously
            talking about T-shirts, sweatshirts and caps :)
          </div>
          <Button str={"SEARCH"} dataLayer={["search", "body"]} />
        </div>
        <div className={"right-container"}>
          <div className={"container-picture"}>
            <div className={"picture"}></div>
            <div className={"picture"}></div>
          </div>
          <div className={"circle"}></div>
        </div>
      </div>
      <div className={"big-container"}>
        {lst_big_div.map((a) => (
          <div className={"card"}>
            <div>{a[0]}</div>
            <Button str={a[1]} dataLayer={[a[1], "body"]} />
          </div>
        ))}
      </div>
      <div className={"collection-container"}>
        <div className={"title"}>LES FAVORIS DU MOMENTS</div>
        <div className={"scroll-view-collection"}>
          {lst_favourites.map((a) => (
            <ProductCard lst={a} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};
