export const NavAdmin = (set) => {
  console.log(set["set"]);

  const lst_pages = [
    ["user", "fa-solid fa-user", () => set["set"](2)],
    ["product", "fa-solid fa-shirt", () => set["set"](4)],
    ["design", "fa-solid fa-paintbrush", () => set["set"](5)],
    [
      "deco",
      "fa-solid fa-rocket",
      () => {
        document.cookie =
          "email=; expires=Mon, 02 Oct 2000 01:00:00 GMT; path=/";
        document.location = "/admin";
      },
    ],
  ];

  return (
    <div className={"admin-nav"}>
      {lst_pages.map((a) => (
        <div onClick={a[2]} className={"btn"}>
          <i className={a[1]}></i>
          <h4>{a[0]}</h4>
        </div>
      ))}
    </div>
  );
};
