import { Button } from "./button";
import { container_alert } from "../module/alert";
import axios from "axios";
import { start_url_fetch } from "../module/settings";
import { useEffect } from "react";

export const Newsletter = () => {
  useEffect(() => {
    const slidingNewsLetters = document.querySelector(".container-newsletter");

    window.addEventListener("scroll", () => {
      const { scrollTop, clientHeight } = document.documentElement;
      const topElementToTopViewport =
        slidingNewsLetters.getBoundingClientRect().top;

      if (
        scrollTop >
        (scrollTop + topElementToTopViewport).toFixed() - clientHeight * 0.5
      ) {
        slidingNewsLetters.classList.add("active");
      }
    });
  }, []);

  return (
    <div className={"container-newsletter"}>
      <div className={"title"}>Suivez nos aventures</div>
      <div className={"description"}>
        Et recevez nos offres promotionnelles en avant-première !
      </div>
      <div className={"form"}>
        <input
          type={"text"}
          id={"emailnl"}
          placeholder={"Votre adresse éléctronique"}
        />
        <Button
          str={"S'inscrire"}
          cmd={() => {
            window.dataLayer.push({
              event_name: "newsletter_signup",
              event: "dlevent",
            });
            if (document.querySelector("#emailnl").value === "") {
              container_alert("INVALID EMAIL");
            } else {
              axios(
                start_url_fetch +
                  "/in_nl?email=" +
                  document.querySelector("#emailnl").value
              );
              container_alert("WELCOME :)");
            }
          }}
        />
      </div>
    </div>
  );
};
