export const File = (data) => {
  return (
    <div className={"file"}>
      {data["data"].map((a, i) => (
        <div className={"container"}>
          {i !== 0 ? <div className={"line"}></div> : ""}
          <div className={"text"}>{a}</div>
          <div className={"circle"}>{i + 1}</div>
        </div>
      ))}
    </div>
  );
};
