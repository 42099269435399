import { Nav } from "../component/nav";
import { useEffect, useState } from "react";
import { File } from "../component/file";
import axios from "axios";
import { start_url_fetch } from "../module/settings";
import {
  add_payment_info_data_layer,
  add_shipping_info_data_layer,
  begin_checkout__dataLayer,
  button_click_data_layer,
  init_dataLayer,
  purchase__dataLayer,
  remove_from_cart__dataLayer,
  view_cart__dataLayer,
} from "../module/dataLayer";

function calc_total(array) {
  let total = 0;
  for (let i = 0; i < array.length; i++) total += parseInt(array[i][1]);
  return total;
}

function change_file_color(nb) {
  document.querySelector(
    ".file .container:nth-child(" + nb + ") .circle"
  ).style.background = "#5a4cff";
  document.querySelector(
    ".file .container:nth-child(" + nb + ") .circle"
  ).style.color = "#fff";
  document.querySelector(
    ".file .container:nth-child(" + nb + ") .circle"
  ).style.border = "2px solid #5a4cff";
  document.querySelector(
    ".file .container:nth-child(" + nb + ") .text"
  ).style.color = "#5a4cff";
}

function active_point(ele) {
  const div = document.querySelector(".point#" + ele);
  if (!div.classList.contains("active")) {
    document.querySelector(".point#" + ele).classList.add("active");
  } else {
    document.querySelector(".point#" + ele).classList.remove("active");
  }

  // because radio button
  switch (ele) {
    case "a":
      button_click_data_layer(["same delivery address", "body"]);
      document.querySelector(".point#b").classList.remove("active");
      break;
    case "b":
      button_click_data_layer(["other delivery address", "body"]);
      document.querySelector(".point#a").classList.remove("active");
      break;
    case "c":
      button_click_data_layer(["easy delivery", "body"]);
      document.querySelector(".point#d").classList.remove("active");
      document.querySelector(".point#e").classList.remove("active");
      break;
    case "d":
      button_click_data_layer(["quick delivery", "body"]);
      document.querySelector(".point#c").classList.remove("active");
      document.querySelector(".point#e").classList.remove("active");
      break;
    case "e":
      button_click_data_layer(["relay point delivery", "body"]);
      document.querySelector(".point#d").classList.remove("active");
      document.querySelector(".point#c").classList.remove("active");
      break;
    case "f":
      button_click_data_layer(["Visa", "body"]);
      document.querySelector(".point#g").classList.remove("active");
      document.querySelector(".point#h").classList.remove("active");
      document.querySelector(".point#i").classList.remove("active");
      break;
    case "g":
      button_click_data_layer(["Master Card", "body"]);
      document.querySelector(".point#f").classList.remove("active");
      document.querySelector(".point#h").classList.remove("active");
      document.querySelector(".point#i").classList.remove("active");
      break;
    case "h":
      button_click_data_layer(["Paypal", "body"]);
      document.querySelector(".point#g").classList.remove("active");
      document.querySelector(".point#f").classList.remove("active");
      document.querySelector(".point#i").classList.remove("active");
      break;
    case "i":
      button_click_data_layer(["payment in 3 installments", "body"]);
      document.querySelector(".point#g").classList.remove("active");
      document.querySelector(".point#h").classList.remove("active");
      document.querySelector(".point#f").classList.remove("active");
      break;
  }
}

function change_step(step, data) {
  if (step === 1) {
    const lst_article = document.querySelectorAll(".remove_article");
    for (let i = 0; i < lst_article.length; i++) {
      lst_article[i].style.display = "None";
    }
    const shipping_tiers = document
      .getElementById("c")
      .classList.contains("active")
      ? "easy delivery"
      : document.getElementById("d").classList.contains("active")
      ? "quick delivery"
      : "relay point delivery";

    add_shipping_info_data_layer(data, shipping_tiers);
    init_dataLayer(["payment", "funnel", "payment", "", ""]);
    document.querySelector(".container-pay-page").scrollBy(0, 0);
    document.querySelector(".step.first").classList.remove("active");
    document.querySelector(".step.sec").classList.add("active");
  } else if (step === 2) {
    const payment = document.getElementById("f").classList.contains("active")
      ? "Visa"
      : document.getElementById("g").classList.contains("active")
      ? "MasterCard"
      : "Paypal";
    add_payment_info_data_layer(data, payment);
    init_dataLayer([
      "purchase confirmation",
      "funnel",
      "purchase confirmation",
      "",
      "",
    ]);
    axios(
      start_url_fetch +
        "/id_cart?email=" +
        document.cookie.split("email=")[1].split(",")[0] +
        "&password=" +
        document.cookie.split("password=")[1].split(";")[0]
    )
.then((result) => {
      purchase__dataLayer(data, result["data"]);
    });
    document.querySelector(".step.sec").classList.remove("active");
    document.querySelector(".step.third").classList.add("active");
    axios(
      start_url_fetch +
        "/valid_cart?email=" +
        document.cookie.split("email=")[1].split(",")[0]
    );
  }
  change_file_color(step + 1);
}

export const Payment = () => {
  if (document.cookie.split("email").length - 1 === 0) {
    window.location = "/user";
  }

  const [data_product, set_data_product] = useState([]);
  const [long_data_product, set_long_data_product] = useState([]);

  useEffect(() => {
    init_dataLayer(["delivery", "funnel", "delivery", "", ""]);
    axios(
      start_url_fetch +
        "/get_product_in_card_long?email=" +
        document.cookie.split("email=")[1].split(",")[0] +
        "&password=" +
        document.cookie.split("password=")[1].split(";")[0]
    ).then((r) => {
      let data = [];
      for (let i = 0; i < r.data.split(";").length - 1; i++) {
        data.push(r.data.split(";")[i].split(","));
      }

      begin_checkout__dataLayer(data);
      view_cart__dataLayer(data);
      console.log(data);
      set_long_data_product(data);
    });
    axios(
      start_url_fetch +
        "/get_product_in_card?email=" +
        document.cookie.split("email=")[1].split(",")[0] +
        "&password=" +
        document.cookie.split("password=")[1].split(";")[0]
    ).then((r) => {
      if (r.data.split(";").length - 1 === 0) {
        window.location = "/search";
      }
      let data = [];
      for (let i = 0; i < r.data.split(";").length - 1; i++) {
        data.push(r.data.split(";")[i].split(","));
      }
      set_data_product(data);
    });
  }, []);

  const data_file = ["Livraison", "Paiement", "Confirmation"];
  const [step, set_step] = useState(1);

  setTimeout(() => {
    document.querySelector(".step.first").classList.add("active");
    change_file_color(1);
  }, 500);

  return (
    <div>
      <Nav />
      <div className={"container-pay-page"}>
        <div className={"left"}>
          <File data={data_file} step={step} />
          <div className={"step first"}>
            <div className={"label"}>Adresse e-mail</div>
            <input type={"text"} placeholder={"email"} />
            <div className={"label"}>Adresse de Livraison</div>
            <input type={"text"} placeholder={"France"} />
            <input
              type={"text"}
              className={"demi"}
              placeholder={"Code postal"}
            />
            <input type={"text"} className={"demi"} placeholder={"Ville"} />
            <input type={"text"} placeholder={"Adresse postale"} />
            <input type={"text"} className={"demi"} placeholder={"Prénom"} />
            <input type={"text"} className={"demi"} placeholder={"Nom"} />
            <input type={"text"} placeholder={"Téléphone"} />

            <div className={"label"}>Adresse de facturation</div>
            <div className={"input-radio"} onClick={() => active_point("a")}>
              <div className={"point"} id={"a"}></div>
              <div className={"text"}>Identique à l'adresse de livraison</div>
            </div>
            <div className={"input-radio"} onClick={() => active_point("b")}>
              <div className={"point"} id={"b"}></div>
              <div className={"text"}>
                Utiliser une adresse de facturation différente
              </div>
            </div>
            <div className={"label"}>Mode d'éxpédition</div>
            <div className={"input-radio"} onClick={() => active_point("c")}>
              <div className={"point"} id={"c"}></div>
              <div className={"text-container"}>
                <div className={"text"}>Livraison simple</div>
                <div className={"text"}>0,00€</div>
              </div>
            </div>
            <div className={"input-radio"} onClick={() => active_point("d")}>
              <div className={"point"} id={"d"}></div>
              <div className={"text-container"}>
                <div className={"text"}>Livraison rapide</div>
                <div className={"text"}>3,90€</div>
              </div>
            </div>
            <div className={"input-radio"} onClick={() => active_point("e")}>
              <div className={"point"} id={"e"}></div>
              <div className={"text-container"}>
                <div className={"text"}>Livraison en point relai</div>
                <div className={"text"}>0,00€</div>
              </div>
            </div>
            <div
              className={"btn"}
              onClick={() => change_step(1, long_data_product)}
            >
              Aller au paiement
            </div>
          </div>
          <div className={"step sec"}>
            <div className={"label"}>Paiement</div>
            <h5>Toutes les transactions sont sécurisées et chiffrées</h5>

            <div className={"input-radio"} onClick={() => active_point("f")}>
              <div className={"point"} id={"f"}></div>
              <div className={"text-container"}>
                <div className={"text"}>Visa</div>
                <div className={"logo"}></div>
              </div>
            </div>
            <div className={"input-radio"} onClick={() => active_point("g")}>
              <div className={"point"} id={"g"}></div>
              <div className={"text-container"}>
                <div className={"text"}>MasterCard</div>
                <div className={"logo"}></div>
              </div>
            </div>
            <div className={"input-radio"} onClick={() => active_point("h")}>
              <div className={"point"} id={"h"}></div>
              <div className={"text-container"}>
                <div className={"text"}>Paypal</div>
                <div className={"logo"}></div>
              </div>
            </div>
            <div className={"input-radio"} onClick={() => active_point("i")}>
              <div className={"point"} id={"i"}></div>
              <div className={"text-container"}>
                <div className={"text"}>Paiement en 3X sans frais</div>
                <div className={"logo"}></div>
              </div>
            </div>
            <div className={"container-box"}>
              <div className={"box"}>
                <div className={"title"}>Adresse de facturation</div>
                <div className={"info"}>Adresse postale</div>
                <div className={"info"}>Ville et code postale</div>
                <div className={"info"}>Pays</div>
                <div className={"info"}>Téléphone</div>
              </div>
              <div className={"box"}>
                <div className={"title"}>Adresse de livraison</div>
                <div className={"info"}>Adresse postale</div>
                <div className={"info"}>Ville et code postale</div>
                <div className={"info"}>Pays</div>
                <div className={"info"}>Téléphone</div>
              </div>
            </div>
            <div
              className={"btn"}
              id={"pay"}
              onClick={() => change_step(2, long_data_product)}
            >
              Payer
            </div>
          </div>

          <div className={"step third"}>
            <img
              alt={"img"}
              src={
                "https://media.discordapp.net/attachments/1084071570567335956/1111597947705102336/image.png?width=570&height=307"
              }
            />
            <div className={"img"}></div>
            <div className={"container-box"}>
              <div className={"box"}>
                <div className={"title"}>Adresse de facturation</div>
                <div className={"info"}>Adresse postale</div>
                <div className={"info"}>Ville et code postale</div>
                <div className={"info"}>Pays</div>
                <div className={"info"}>Téléphone</div>
              </div>
              <div className={"box"}>
                <div className={"title"}>Adresse de livraison</div>
                <div className={"info"}>Adresse postale</div>
                <div className={"info"}>Ville et code postale</div>
                <div className={"info"}>Pays</div>
                <div className={"info"}>Téléphone</div>
              </div>
            </div>
            <div
              className={"btn"}
              id={"home"}
              onClick={() => {
                button_click_data_layer(["return to home", "home"]);
                window.location = "/";
              }}
            >
              Home
            </div>
          </div>
        </div>
        <div className={"right"}>
          <div className={"title"}>Récapitulatif de votre commande</div>
          {data_product.map((a, i) => (
            <div className={"container-block-product-price-info"}>
              <div className={"line"}></div>
              <div className={"container-article-right"}>
                <i
                  className="fa-solid fa-x remove_article"
                  style={{ position: "relative", top: -25, left: "75%" }}
                  onClick={() => {
                    remove_from_cart__dataLayer(long_data_product[i]);
                    const temp = [];
                    for (let j = 0; j < long_data_product.length; j++) {
                      if (j !== i) {
                        temp.push(long_data_product[i]);
                      }
                    }
                    set_long_data_product(temp);
                    document.querySelectorAll(
                      ".container-block-product-price-info"
                    )[i].style.display = "none";
                    document.querySelector("#total-int").innerHTML =
                      parseInt(document.querySelector("#total-int").innerHTML) -
                      a[1];
                    axios(
                      start_url_fetch +
                        "/remove_article?email=" +
                        document.cookie.split("email=")[1].split(",")[0] +
                        " &password= " +
                        document.cookie.split("password=")[1].split(";")[0] +
                        "&index=" +
                        i
                    );
                  }}
                ></i>
                <h2>{a[0]}</h2>
                <h3>{a[1]}€</h3>
              </div>
            </div>
          ))}
          <div className={"line"}></div>
          <div className={"container-article-right"}>
            <h3>Total</h3>
            <h3 id={"total"}>
              <span id={"total-int"}>{calc_total(data_product)}</span>€
            </h3>
          </div>
          <div className={"line"}></div>
        </div>
      </div>
    </div>
  );
};
