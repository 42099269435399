import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./pages/home";
import { Error404 } from "./pages/error404";
import { Article } from "./pages/article";
import { Search } from "./pages/search";
import { Payment } from "./pages/pay";
import { Admin } from "./pages/admin";
import { User } from "./pages/user";
import { Singing } from "./pages/singin";
import { Password } from "./pages/password";
import { Like } from "./pages/like";
import { Privacy } from "./pages/privacy";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<Home />}></Route>
        <Route path={"/article"} element={<Article />}></Route>
        <Route path={"/search"} element={<Search />}></Route>
        <Route path={"/pay"} element={<Payment />}></Route>
        <Route path={"/user"} element={<User />}></Route>
        <Route path={"/singing"} element={<Singing />}></Route>
        <Route path={"/password"} element={<Password />}></Route>
        <Route path={"/like"} element={<Like />}></Route>
        <Route path={"/privacy"} element={<Privacy />}></Route>
        <Route path={"/admin"} element={<Admin set_email />}></Route>
        <Route path={"*"} element={<Error404 />}></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
