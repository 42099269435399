import { Link } from "react-router-dom";
import { lst_possibility } from "../module/product";
import axios from "axios";
import { start_url_fetch } from "../module/settings";
import { container_alert } from "../module/alert";
import { item_list } from "../module/dataLayer";

export const ProductCard = ({ lst }) => {
  lst = lst[0];

  const link = "/article?" + lst[9];

  if (lst[10] == 1) return;

  return (
    <div onClick={() => (document.location.href = link)}
      className={"product-card"}
      style={{
        "--url": lst_possibility[lst[3]][0][lst[4]],
        "--url2": lst_possibility[lst[3]][1][lst[4]],
      }}
    >
      <div
        className={"img"}
        style={{
          position: "relative",
          top: lst[6].toString() + "px",
          left: "0px",
        }}
      >
        <img
          src={"https://victordalet.lescigales.org/dl/" + lst[8] + ".png"}
          style={{ width: lst[7].toString() + "px" }}
          alt={"logo"}
        />
      </div>
      <div
        className={"step-txt"}
        style={{ "--dec": -lst[7].toString() + "px" }}
      >
        <div className={"title"}>{lst[0]}</div>
        <Link
          to={link}
          className={"price"}
          onClick={() => {
            item_list(lst);
          }}
        >
          {lst[2]} €
        </Link>
      </div>
      <div className={"like"} style={{ opacity: 0 }}>
        <i className={"fa-solid fa-heart"}></i>
      </div>
      {window.location.href.split("admin").length !== 1 ? (
        <div
          className={"like"}
          style={{ transform: "translateY(-50px)" }}
          onClick={() => {
            axios(start_url_fetch + "/ban_article?id=" + lst[9]);
            container_alert("Produit en cours de suppression");
          }}
        >
          <i className={"fa-solid fa-trash"}></i>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
