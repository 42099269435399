import { button_click_data_layer } from "../module/dataLayer";

export const Button = ({
  str,
  cmd = () => (window.location = "/search"),
  dataLayer = [],
}) => {
  return (
    <div
      className={"button"}
      onClick={() => {
        cmd();
        button_click_data_layer(dataLayer);
      }}
    >
      {str}
    </div>
  );
};
