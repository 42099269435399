import { Newsletter } from "./newsletter";
import { Slogan } from "./slogan";

export const Footer = () => {
  return (
    <div>
      <Newsletter />
      <Slogan />
      <footer>
        <h2>&copy; Digitalinkers | 2023</h2>
      </footer>
    </div>
  );
};
