import {Nav} from "../component/nav";

export const Password = () => {
    return (
        <div>
            <Nav/>
            <div className={"admin-page"}>
                <div className={"container-form"}>
                    <h1 className={"title"}>RESET PASSWORD</h1>
                    <input type={"text"} placeholder={"email"} name={"email"}/>
                    <input type={"text"} placeholder={"password"} name={"password"}/>
                    <input type={"password"} placeholder={"code"} name={"code"}/>
                    <div className={"send"}>RESET</div>
                    <h3 onClick={()=> window.location = '/user'}>back</h3>
                </div>
            </div>
        </div>

    );
}
