import axios from "axios";
import { start_url_fetch } from "./settings";
import { container_alert } from "./alert";

export async function test_connection(nb) {
  const second_url = nb === 1 ? "/connect" : "/connect_to_back";

  const url =
    start_url_fetch +
    second_url +
    "?email=" +
    document.querySelector('input[name="email"]').value +
    "&password=" +
    document.querySelector('input[name="password"]').value;
  axios(url, {
    method: "GET",
  }).then((r) => {
    if (r.data !== "!ok") {
      console.log(document.querySelector('input[name="email"]').value);
      document.cookie =
        "email=" +
        document.querySelector('input[name="email"]').value +
        ",id=" +
        r.data +
        ",password=" +
        document.querySelector('input[name="password"]').value +
        "; path=/";
      nb === 1 ? (window.location = "/user") : (window.location = "/admin");
    } else {
      container_alert("Echec de la connection");
    }
  });
}
