import { Nav } from "../component/nav";
import { container_alert } from "../module/alert";
import axios from "axios";
import { start_url_fetch } from "../module/settings";
import { button_click_data_layer, init_dataLayer } from "../module/dataLayer";

function validateEmail(email) {
  const res =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return res.test(String(email).toLowerCase());
}

function validatePassword(password) {
  return password.length > 6;
}

export const Singing = () => {
  init_dataLayer(["signup", "account", "", "", ""]);

  if (document.cookie.split("email").length - 1 !== 0) {
    window.location = "/user";
    return;
  }

  return (
    <div>
      <Nav />
      <div className={"admin-page"}>
        <div className={"container-form"}>
          <h1 className={"title"}>SIGN IN</h1>
          <input
            type={"text"}
            placeholder={"Email"}
            id={"email-s"}
            name={"email"}
          />
          <input
            type={"password"}
            placeholder={"Password"}
            id={"password-s"}
            name={"password"}
          />
          <input
            type={"password"}
            placeholder={"Confirm password"}
            id={"password-c"}
            name={"password-c"}
          />
          <div
            className={"send"}
            onClick={() => {
              window.dataLayer.push({
                event_name: "account_creation",
                event: "dlevent",
              });
              const email = document.querySelector("#email-s").value;
              const password1 = document.querySelector("#password-c").value;
              const password2 = document.querySelector("#password-s").value;
              if (password1 !== password2) {
                container_alert("Passwords do not match");
              } else if (!validateEmail(email)) {
                container_alert("Invalid email");
              } else if (!validatePassword(password1)) {
                container_alert("Invalid Password");
              } else {
                axios(
                  start_url_fetch +
                    "/create_user?name=" +
                    email.split("@")[0] +
                    "&email=" +
                    email +
                    "&password=" +
                    password1,
                  {
                    method: "GET",
                  }
                ).then((r) => {
                  document.cookie =
                    "email=" +
                    email +
                    ",id=" +
                    r.data +
                    ",password=" +
                    password1 +
                    "; path=/";
                  window.location = "/user";
                });
              }
            }}
          >
            SIGN IN
          </div>
          <h3
            onClick={() => {
              button_click_data_layer(["login", "body"]);
              window.location = "/user";
            }}
          >
            Log in
          </h3>
        </div>
      </div>
    </div>
  );
};
