import React from "react";
import { Nav } from "../component/nav";

export class Privacy extends React.Component {
  render() {
    return (
      <div>
        <Nav />
        <div className={"page-privacy"}>
          <h2>PRIVACY</h2>
          <h4>
            Notre application s'adresse aux utilisateurs de tous âges , y
            compris aux enfants de moins de 13 ans . Nous sommes engagés à
            respecter la vie privée de nos utilisateurs et à protéger les
            informations personnelles que nous collectons dans le cadre de
            l'utilisation de notre application en conformité avec le Règlement
            général sur la protection des données (RGPD). Collecte de données
            personnelles Nous collectons certaines informations personnelles des
            utilisateurs tels que des noms, adresses e-mail, numéros de
            téléphone, âges, adresses IP, données de localisation, etc. Nous ne
            collectons les données que des utilisateurs nécessaires au bon
            fonctionnement de l'application, ainsi qu'à des fins statistiques et
            d'analyse. Utilisation de données personnelles Nous utilisons les
            données personnelles collectées pour fournir aux utilisateurs une
            expérience utilisateur personnalisée et adaptée à leurs besoins.
            Nous n'utiliserons pas les données personnelles collectées à des
            fins de marketing sans obtenir préalablement le consentement
            explicite de l'utilisateur. Avis aux parents Si vous avez moins de
            13 ans , vous devez demander la permission à vos parents ou à un
            tuteur avant d'utiliser notre application. Nous encourageons tous
            les parents et tuteurs à surveiller l'utilisation de l'application
            par leurs enfants et à nous contacter immédiatement si des données
            personnelles des enfants ont été collectées sans consentement
            préalable. Sécurité des données personnelles Nous prenons la
            sécurité des données personnelles de nos utilisateurs très au
            sérieux. Nous avons mis en place des mesures de sécurité adaptées
            pour protéger les données personnelles collectées dans notre
            application contre toute utilisation non autorisée ou illégale
            Modifications des règles de confidentialité Nous nous réservons le
            droit de modifier ou de mettre à jour les règles de confidentialité
            de temps à autre. Nous vous encourageons donc à consulter
            régulièrement cette page pour prendre connaissance de tout
            changement.
          </h4>
        </div>
      </div>
    );
  }
}
