import { button_click_data_layer } from "../module/dataLayer";

export const Nav = () => {
  const lst_item_nav = [
    [
      "https://media.discordapp.net/attachments/1084071570567335956/1111315385996820540/image.png?width=255&height=177",
    ],
    [
      "Tee-Shirt",
      "/search?0",
      "url(https://media.discordapp.net/attachments/1113357364536541204/1113850805859131523/image.png?width=110&height=110)",
    ],
    [
      "Hoodie",
      "/search?1",
      "url(https://media.discordapp.net/attachments/1113357364536541204/1113850839535194162/image.png?width=125&height=125)",
    ],
    [
      "Cap",
      "/search?2",
      "url(https://media.discordapp.net/attachments/1113357364536541204/1113850865917370448/image.png?width=110&height=110)",
    ],
  ];
  const lst_icon_nav = [
    ["fa-regular fa-heart", "/like"],
    ["fa-solid fa-user", "/user"],
    ["fa-solid fa-bag-shopping", "/pay"],
  ];

  return (
    <div>
      <div className={"nav-link"}>
        <div className={"step-item"}>
          {lst_item_nav.map((a, i) =>
            i !== 0 ? (
              <div
                className={"item"}
                onClick={() => {
                  button_click_data_layer([a[0], "header"]);
                  window.location = a[1];
                }}
              >
                <i style={{ "--url": a[2] }}></i>
                <span>{a[0]}</span>
              </div>
            ) : (
              <img
                onClick={() => {
                  button_click_data_layer(["home", "header"]);
                  window.location = "/";
                }}
                alt={"logo"}
                src={a[0]}
              />
            )
          )}
        </div>
        <div className={"step-icon"}>
          {lst_icon_nav.map((a) => (
            <i
              onClick={() => {
                button_click_data_layer([a[1], "header"]);
                window.location = a[1];
              }}
              className={a[0]}
            ></i>
          ))}
        </div>
      </div>
    </div>
  );
};
