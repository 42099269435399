import { test_connection } from "../module/connect";
import { useEffect, useState } from "react";
import { NavAdmin } from "../component/admin_nav";
import { Design } from "../component/design";
import { Array } from "../component/array";
import axios from "axios";
import { start_url_fetch } from "../module/settings";
import { container_alert } from "../module/alert";
import { ProductCard } from "../component/product_card";

export const Admin = () => {
  const is_connected =
    document.cookie.split("email").length - 1 === 0 ? "" : "ok";

  const [loca, set_loca] = useState(2);

  let active = false;

  const lst_data_user_head = ["Nom", "Email", "Grade", "up", "down"];
  const lst_data_log_head = ["page", "user", "date"];
  const [lst_data_user_body, set_lst_data_user_body] = useState([]);
  const [lst_data_log_body, set_lst_data_log_body] = useState([]);

  const [product, set_product] = useState([]);

  useEffect(() => {
    if (is_connected !== "") {
      // eslint-disable-next-line no-undef
      const url =
        start_url_fetch +
        "/connect_to_back" +
        "?email=" +
        document.cookie.split("email=")[1].split(",")[0] +
        "&password=" +
        document.cookie.split("password=")[1].split(";")[0];

      axios(url, {
        method: "GET",
      }).then((r) => {
        if (r.data === "!ok") {
          window.location = "/user";
        }
      });

      axios(
        start_url_fetch +
          "/get_product_by_tools_1?color=" +
          0 +
          "&min=" +
          0 +
          "&max=" +
          1000
      ).then((r) => {
        let data = [];
        for (let i = 0; i < r.data.split(";").length - 1; i++) {
          data.push([r.data.split(";")[i].split(",")]);
        }
        set_product(data);
      });

      // eslint-disable-next-line no-undef
      axios(
        start_url_fetch +
          "/get_user?email=" +
          document.cookie.split("email=")[1].split(",")[0] +
          "&password=" +
          document.cookie.split("password=")[1].split(";")[0],
        {
          method: "GET",
        }
      ).then((r) => {
        let data = [];
        for (let i = 0; i < r["data"].length; i++) {
          data.push([]);
          for (const key in r["data"][i]) {
            data[i].push(r["data"][i][key]);
          }
          data[i].push(
            <i
              className="fa-solid fa-plus"
              onClick={() => {
                axios(start_url_fetch + "/set_grade?mod=1&mail=" + data[i][1]);
              }}
            ></i>
          );
          data[i].push(
            <i
              className="fa-solid fa-minus"
              onClick={() => {
                axios(start_url_fetch + "/set_grade?mod=0&mail=" + data[i][1]);
              }}
            ></i>
          );
        }
        set_lst_data_user_body(data);
      });
    }
  }, []);

  return (
    <div>
      {is_connected === "" ? (
        <div className={"admin-page"}>
          <div className={"container-form"}>
            <h1 className={"title"}>CONNEXION ADMIN</h1>
            <input type={"text"} placeholder={"email"} name={"email"} />
            <input
              type={"password"}
              placeholder={"password"}
              name={"password"}
            />
            <div className={"send"} onClick={() => test_connection(2)}>
              send
            </div>
          </div>
        </div>
      ) : (
        <div>
          <NavAdmin set={set_loca} />
          <div
            className={"button-add"}
            onClick={() => {
              !active
                ? document
                    .querySelector(".container-add")
                    .classList.add("active")
                : document
                    .querySelector(".container-add")
                    .classList.remove("active");
              active = !active;
            }}
          >
            <i className="fa-solid fa-plus"></i>
          </div>
          {loca === 2 ? (
            <Array head={lst_data_user_head} body={lst_data_user_body} />
          ) : (
            ""
          )}
          {loca === 3 ? (
            <Array head={lst_data_log_head} body={lst_data_log_body} />
          ) : (
            ""
          )}
          {loca === 4 ? (
            <div className={"container-product-search"}>
              {product.map((a) => (
                <ProductCard lst={a} />
              ))}
            </div>
          ) : (
            ""
          )}
          {loca === 5 ? <Design /> : ""}
          <div className={"container-add"}>
            <h3 className={"title"}>AJOUTER UN PRODUIT</h3>
            <input type={"text"} className={"title"} placeholder={"title"} />
            <input
              type={"text"}
              className={"description"}
              placeholder={"description"}
            />
            <input type={"number"} placeholder={"price"} />
            <input
              type={"button"}
              id={"create-product.js"}
              value={"Create Product"}
              onClick={() => set_loca(5)}
            />
            <div
              className={"send"}
              onClick={() => {
                if (document.url) {
                  const title = document.querySelector("input.title").value;
                  const description =
                    document.querySelector("input.title").value;
                  const price = document.querySelector(
                    'input[type="number"]'
                  ).value;
                  if (title === "") {
                    container_alert("Titre Vide");
                  } else if (description === "") {
                    container_alert("Description Vide");
                  } else if (price === "") {
                    container_alert("Indiquer un prix");
                  } else {
                    axios(
                      start_url_fetch +
                        "/new_product?name=" +
                        title +
                        "&description=" +
                        description +
                        "&price=" +
                        price +
                        "&id=" +
                        document.nb +
                        document.url +
                        "&email=" +
                        document.cookie.split("email=")[1].split(",")[0] +
                        "&password=" +
                        document.cookie.split("password=")[1].split(";")[0]
                    );
                    document
                      .querySelector(".container-add")
                      .classList.remove("active");
                  }
                } else {
                  container_alert("PAS DE DESIGN A AJOUTER");
                }
              }}
            >
              AJOUTER
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
