import { useEffect, useState } from "react";
import axios from "axios";
import { start_url_fetch } from "../module/settings";
import { button_click_data_layer } from "../module/dataLayer";

export const Forum = () => {
  const [lst_msg, set_lst_msg] = useState([]);

  async function send_msg() {
    if (document.cookie.split("email").length - 1 === 0) {
      window.location = "/user";
      return;
    }
    axios(
      start_url_fetch +
        "/add_msg?id=" +
        window.location.href.split("?")[1] +
        "&email=" +
        document.cookie.split("email=")[1].split(",")[0] +
        "&content=" +
        document.querySelector("#input-forum").value +
        "&password=" +
        document.cookie.split("password=")[1].split(";")[0]
    );
    document.querySelector("#input-forum").value = "";
    document.querySelector(".send").classList.remove("active");
    setTimeout(() => instant(), 500);
  }

  function instant() {
    axios(
      start_url_fetch +
        "/get_msg_by_forum?id=" +
        window.location.href.split("?")[1]
    ).then((r) => {
      let data = [];
      for (let i = 0; i < r.data.split(";").length - 1; i++) {
        data.push(r.data.split(";")[i].split(","));
      }
      set_lst_msg(data);
    });
  }

  useEffect(() => {
    instant();
    //setInterval(()=>instant(),200)
  }, []);

  return (
    <div className={"forum"}>
      <div className={"container-pres-forum"}>
        <div className={"title"}>Parlez de l'article.</div>
        <div className={"btn"}>Ecrire</div>
      </div>
      <div className={"container-forum"}>
        <div className={"container-msg"}>
          {lst_msg.map((a) => (
            <div className={"msg"}>
              <div className={"user.js"}>{a[0]}</div>
              <div className={"content"}>{a[1]}</div>
            </div>
          ))}
        </div>
        <div className={"container-write"}>
          <input
            type={"text"}
            id={"input-forum"}
            placeholder={"Votre avis"}
            onChange={() => {
              if (document.querySelector("#input-forum").value !== "") {
                document.querySelector(".send").classList.add("active");
              } else {
                document.querySelector(".send").classList.remove("active");
              }
            }}
          />
          <div
            className={"send"}
            onClick={() => {
              send_msg();
              button_click_data_layer(["send message", "body"]);
            }}
          >
            <i className="fa-solid fa-paper-plane"></i>
          </div>
        </div>
      </div>
    </div>
  );
};
