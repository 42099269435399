import {Link} from "react-router-dom";

export const Error404 = () => {
    return (
        <div className={"page-404-container"}>
            <div className={"title"}>Error 404</div>
            <div className={"subTitle"}>Are you lost ? Come back to the home page.</div>
            <Link to={"/"} className={"btn"}>HOME</Link>
        </div>
    )
}
