import { Nav } from "../component/nav";
import { ProductCard } from "../component/product_card";
import { Footer } from "../component/footer";
import { Tools } from "../component/tools";
import axios from "axios";
import { start_url_fetch } from "../module/settings";
import { useEffect, useState } from "react";
import { init_dataLayer, view_item_list } from "../module/dataLayer";

export const Search = () => {
  const [product, set_product] = useState([]);

  const cat = parseInt(window.location.href.split("?")[1]);

  const page_cat = cat === 0 ? "Tee shirt" : cat === 1 ? "hoodie" : "cap";
  useEffect(() => {
    init_dataLayer(["product list", "product", "product list", page_cat, ""]);
    isNaN(cat)
      ? axios(
          start_url_fetch +
            "/get_product_by_tools_1?color=" +
            0 +
            "&min=" +
            0 +
            "&max=" +
            1000 +
            "&cat=" +
            cat
        ).then((r) => {
          let data = [];
          for (let i = 0; i < r.data.split(";").length - 1; i++) {
            data.push([r.data.split(";")[i].split(",")]);
          }
          view_item_list(data, page_cat);
          set_product(data);
        })
      : axios(
          start_url_fetch +
            "/get_product_by_tools_2?color=" +
            0 +
            "&min=" +
            0 +
            "&max=" +
            1000 +
            "&cat=" +
            cat
        ).then((r) => {
          let data = [];
          for (let i = 0; i < r.data.split(";").length - 1; i++) {
            data.push([r.data.split(";")[i].split(",")]);
          }
          view_item_list(data, page_cat);
          set_product(data);
        });
  }, []);

  return (
    <div>
      <Nav />
      <Tools set={set_product} />
      <div className={"nb"}>{product.length} articles trouvés</div>
      <div className={"container-product-search"}>
        {product.map((a) => (
          <ProductCard lst={a} />
        ))}
      </div>
      <Footer />
    </div>
  );
};
