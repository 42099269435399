function is_mobile() {
  let check = false;
  ((a) => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export function init_dataLayer(data) {
  const temp = {
    page_name: data[0],
    page_type: data[1],
    page_sub_type: data[2],
    page_category1: data[3],
    page_category2: data[4],
    environment_work: "prod",
    environment_device: is_mobile() ? "mobile" : "desktop",
    environment_country: window.navigator.language,
    user_authentification: document.cookie.split("email").length - 1,
    user_timestamp: Date.now(),
    user_id:
      document.cookie.split(",id=").length - 1 === 0
        ? -1
        : document.cookie.split(",id=")[1].split(",")[0],
    event:
      document.location.href.split("pay").length - 1 === 1
        ? "virtual_pageview"
        : "pageview",
  };
  if (document.location.href.split("pay").length - 1 === 1) {
    window.dataLayer.push(temp);
  } else {
    window.dataLayer = [temp];
  }
}

export function button_click_data_layer(data) {
  if (data.length !== 0) {
    window.dataLayer.push({
      event_name: "button_click",
      button_name: data[0],
      button_location: data[1],
      event: "dlevent",
    });
  }
}

function item(a, quantity = 1) {
  return[
    {
      item_id: a[9],
      item_name: a[0],
      coupon: "",
      index: 0,
      item_brand: "dl",
      item_category:
        parseInt(a[3]) === 0
          ? "tee shirt"
          : parseInt(a[3]) === 1
          ? "hoodie"
          : "cap",
      item_list_id: "",
      item_list_name: "",
      item_variant:
        a[4] === "0"
          ? "grey"
          : a[4] === "1"
          ? "pink"
          : a[4] === "2"
          ? "green"
          : "blue",
      location_id: "",
      price: a[2],
      quantity: quantity,
    }][0];
}

export function view_item_list(data, name) {
  const items = [];
  data.map((a) => items.push(item(a[0])));
  window.dataLayer.push({
    event: "view_item_list",
    ecommerce: {
      item_list_id: name,
      item_list_name: name,
      items: items,
    },
  });
}

export function item_list(lst) {
  window.dataLayer.push({
    event: "view_item",
    ecommerce: {
      item_list_id: lst[0][0],
      item_list_name: lst[0][0],
      items: item(lst[0]),
    },
  });
}

export function whilst_item_data_layer(lst) {
  window.dataLayer.push({
    event: "add_to_whilst",
    ecommerce: {
      currency: "€",
      value: lst[0][2],
      items: item(lst),
    },
  });
}

export function list_sort_data_layer(sort) {
  window.dataLayer.push({
    event_name: "list_sort",
    list_name: sort,
    event: "dlevent",
  });
}

export function add_to_card_data_layer(lst, quantity) {
  window.dataLayer.push({
    event: "add_to_cart",
    ecommerce: { currency: "€", values: lst[2], items: item(lst, quantity) },
  });
}

function sum_price_article(array) {
  let sum = 0;
  for (let i = 0; i < array.length; i++) {
    sum += parseInt(array[i][2]);
  }
  return sum;
}

export function add_shipping_info_data_layer(data, shipping_tiers) {
  const items = [];
  data.map((a) => items.push(item(a)));
  window.dataLayer.push({
    event: "add_shipping_info",
    ecommerce: {
      currency: "€",
      values: sum_price_article(data),
      coupon: "",
      shipping_tiers: shipping_tiers,
      items: items,
    },
  });
}

export function add_payment_info_data_layer(data, payment_type) {
  const items = [];
  data.map((a) => items.push(item(a)));
  window.dataLayer.push({
    event: "add_payment_info",
    ecommerce: {
      currency: "€",
      values: sum_price_article(data),
      coupon: "",
      payment_type: payment_type,
      items: items,
    },
  });
}

export function remove_from_cart__dataLayer(data) {
  window.dataLayer.push({
    event: "remove_from_cart",
    ecommerce: {
      currency: "€",
      values: data[2],
      items: item(data),
    },
  });
}

export function view_cart__dataLayer(data) {
  const items = [];
  data.map((a) => items.push(item(a)));
  window.dataLayer.push({
    event: "view_cart",
    ecommerce: {
      currency: "€",
      values: sum_price_article(data).toString(),
      items: items,
    },
  });
}

export function account_login_event__dataLayer() {
  window.dataLayer.push({ event_name: "account_login", event: "dlevent" });
}

export function begin_checkout__dataLayer(data) {
  const items = [];
  data.map((a) => items.push(item(a)));
  window.dataLayer.push({
    event: "begin_checkout",
    ecommerce: {
      currency: "€",
      values: sum_price_article(data).toString(),
      items: items,
    },
  });
}

export function purchase__dataLayer(data, t_id) {
  const items = [];
  data.map((a) => items.push(item(a)));
  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      transaction_id: t_id.toString(),
      currency: "€",
      values: sum_price_article(data).toString(),
      tax: (sum_price_article(data) * 0.2).toString(),
      items: items,
    },
  });
}

export function event_list_sort__dataLayer(name, type, value) {
  window.dataLayer.push({
    event: "dlevent",
    event_name: "list_sort",
    list_name: name,
    sort_type : type,
    sort_value: value
  });
}
