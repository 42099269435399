export const Slogan = () => {
  const sentences = [
    ["fa-solid fa-star", "Satisfait ou remboursé", "Retours gratuit"],
    [
      "fa-solid fa-truck",
      "Livraison express",
      "Disponible sur Paris le jour même",
    ],
    [
      "fa-solid fa-credit-card",
      "Paiement sécurisé",
      "Par carte bancaire et Paypal",
    ],
  ];

  return (
    <div className={"slogan"}>
      {sentences.map((a) => (
        <div className={"box"}>
          <i className={a[0]}></i>
          <div className={"title"}>{a[1]}</div>
          <div className={"description"}>{a[2]}</div>
        </div>
      ))}
    </div>
  );
};
