import { Nav } from "../component/nav";
import { Footer } from "../component/footer";
import { Forum } from "../component/forum";
import { useEffect, useState } from "react";
import axios from "axios";
import { start_url_fetch } from "../module/settings";
import { ProductCard } from "../component/product_card";
import { lst_possibility } from "../module/product";
import {
  add_to_card_data_layer,
  button_click_data_layer,
  init_dataLayer,
  item_list,
  view_item_list,
  whilst_item_data_layer,
} from "../module/dataLayer";
import { container_alert } from "../module/alert";

export const Article = () => {
  const [lst_favourites, set_lst_favourites] = useState([]);
  const [product, set_product] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [lst_collection, set_lst_collection] = useState([]);

  

  try {
    useEffect(() => {
      init_dataLayer(["product page", "product", "product page", "", ""]);
      axios(start_url_fetch + "/get_product_fav").then((r) => {
        let data = [];
        for (let i = 0; i < r.data.split(";").length - 1; i++) {
          data.push([r.data.split(";")[i].split(",")]);
        }
        view_item_list(data, "VOUS ALLEZ ADORER CA");
        set_lst_favourites(data);
      });

      axios(
        start_url_fetch +
          "/get_product_id?id=" +
          document.location.href.split("?")[1]
      ).then((r) => {
        item_list([r.data.split(",")]);
        set_product(r.data.split(","));
      });

      if (document.cookie.split("email").length - 1 !== 0) {
        axios(
          start_url_fetch +
            "/add_historic_product?id=" +
            document.location.href.split("?")[1] +
            "&email=" +
            document.cookie.split("email=")[1].split(",")[0] +
            "&password=" +
            document.cookie.split("password=")[1].split(";")[0]
        );

        axios(
          start_url_fetch +
            "/get_historic?email=" +
            document.cookie.split("email=")[1].split(",")[0] +
            "&password=" +
            document.cookie.split("password=")[1].split(";")[0]
        ).then((r) => {
          let data = [];
          for (let i = 0; i < r.data.split(";").length - 1; i++) {
            data.push([r.data.split(";")[i].split(",")]);
          }
          console.log(data);
          view_item_list(data, "RÉCEMMENT VU");
          set_lst_collection(data);
        });
      }
    }, []);

    const is_liked = true;

    return (
      <div>
        <Nav />
        <div className={"container-article"}>
          <div
            className={"img"}
            style={{
              "--url": lst_possibility[product[3]][0][product[4]],
              "--url2": lst_possibility[product[3]][1][product[4]],
            }}
          >
            <div
              style={{
                position: "relative",
                top: product[6].toString() + "px",
                transform: "translateX(50px)",
                left: product[5].toString() + "px",
              }}
            >
              <img
                src={
                  "https://victordalet.lescigales.org/dl/" + product[8] + ".png"
                }
                style={{ width: product[7].toString() + "px" }}
                alt={"logo"}
              />
            </div>
          </div>
          <div className={"information"}>
            <div
              className={"like"}
              onClick={() => {
                whilst_item_data_layer(product);
                if (document.cookie.split("email").length - 1 !== 0) {
                  document.querySelector(".information .like").style
                    .background === "#EEEEEE"
                    ? (document.querySelector(
                        ".information .like"
                      ).style.background = "#efb4b4")
                    : (document.querySelector(
                        ".information .like"
                      ).style.background = "#EEE");
                                    const url_to_fetch =
                    start_url_fetch +
                    "/add_like_product?id=" +
                    document.location.href.split("?")[1] +
                    "&email=" +
                    document.cookie.split("email=")[1].split(",")[0] +
                    "&password=" +
                    document.cookie.split("password=")[1].split(";")[0];
                  console.log(url_to_fetch);
                  axios(url_to_fetch);
                } else {
                  document.location = "/user";
                }
              }}
            >
              <i
                className={"fa-solid fa-heart"}
                style={!is_liked ? { color: "#ee3142" } : { color: "#fff" }}
              ></i>
            </div>
            <div className={"title"}>{product[0]}</div>
            <div className={"price"}>{product[2]} €</div>
            {/*<div className={"container-color"}>
                        {
                            lst_color.map((a) => <div className={"color"} style={{"--clr" : a}} ></div>)
                        }
                    </div>*/}
            <div className={"container-command"}>
              <div className={"container-nb"}>
                <i
                  className="fa-solid fa-minus"
                  onClick={() => {
                    button_click_data_layer(["minus article", "body"]);
                    const str = document.querySelector(".nb-article");
                    if (!(parseInt(str.innerHTML) < 1)) {
                      str.innerHTML = parseInt(str.innerHTML) - 1;
                    }
                  }}
                ></i>
                <div className={"nb-article"}>1</div>
                <i
                  className="fa-solid fa-plus"
                  onClick={() => {
                    button_click_data_layer(["plus article", "body"]);
                    const str = document.querySelector(".nb-article");
                    str.innerHTML = parseInt(str.innerHTML) + 1;
                  }}
                ></i>
              </div>
              <div
                className={"command"}
                onClick={() => {
                   parseInt(document.querySelector(".nb-article").innerHTML) ===1
                    ? container_alert("Votre article a été ajouté au panier")
                    : container_alert("Vos articles ont été ajoutés au panier");
                  add_to_card_data_layer(
                    product,
                    document.querySelector(".nb-article").innerHTML
                  );
                  document.cookie.split("email").length - 1 === 0
                    ? (window.location = "/user")
                    : axios(
                        start_url_fetch +
                          "/add_product?id=" +
                          document.location.href.split("?")[1] +
                          "&nb=" +
                          document.querySelector(".nb-article").innerHTML +
                          "&email=" +
                          document.cookie.split("email=")[1].split(",")[0] +
                          "&password=" +
                          document.cookie.split("password=")[1].split(";")[0]
                      );
                }}
              >
                Ajouter au panier
              </div>
            </div>
            <div className={"description"}>{product[1]}</div>
          </div>
        </div>
        <div className={"collection-container"}>
          <div className={"title"}>VOUS ALLEZ ADORER CA</div>
          <div className={"scroll-view-collection"}>
            {lst_favourites.map((a) => (
              <ProductCard lst={a} />
            ))}
          </div>
        </div>
        <Forum />
        <div className={"collection-container"}>
          <div className={"title"}>RÉCEMMENT VU</div>
          <div className={"scroll-view-collection"}>
            {lst_collection.map((a) => (
              <ProductCard lst={a} />
            ))}
          </div>
        </div>
        <Footer />
      </div>
    );
  } catch (e) {
    window.location = "ArticleSuprr";
  }
};
