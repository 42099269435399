import { Nav } from "../component/nav";
import { test_connection } from "../module/connect";
import { Button } from "../component/button";
import { button_click_data_layer, init_dataLayer, account_login_event__dataLayer } from "../module/dataLayer";

export const User = () => {
  const email = document.cookie.split("email").length - 1 === 0 ? "" : "ok";

  if (email === "") {
    init_dataLayer(["login", "account", "", "", ""]);
  } else {
    init_dataLayer(["profile", "account", "", "", ""]);
  }

  const data_user = [document.cookie];

  return (
    <div>
      <Nav />
      {email === "" ? (
        <div className={"admin-page"}>
          <div className={"container-form"}>
            <h1 className={"title"}>CONNEXION</h1>
            <input type={"text"} placeholder={"email"} name={"email"} />
            <input
              type={"password"}
              placeholder={"password"}
              name={"password"}
            />
            <div
              className={"send"}
              onClick={() => {
                window.dataLayer.push({
                  event_name: "account_login",
                  event: "dlevent",
                });
                test_connection(1);
              }}
            >
              LOG IN
            </div>
            <h3
              onClick={() => {
                account_login_event__dataLayer();
                window.location = "/singing";
              }}
            >
              Sign in
            </h3>
          </div>
        </div>
      ) : (
        <div className={"container-user"}>
          <div className={"head"}>
            <h2>{data_user[0].split("email=")[1].split(",")[0]}</h2>
            <Button
              str={"Disconnection"}
              cmd={() => {
                document.cookie =
                  "email=; expires=Mon, 02 Oct 2000 01:00:00 GMT; path=/";
                window.dataLayer.push({
                  event_name: "account_logout",
                  event: "dlevent",
                });
                document.location = "/user";
              }}
            />
          </div>
          <div className={"container-command"}>
            <Button str={"GO TO EXPLORE"} />
          </div>
        </div>
      )}
    </div>
  );
};
