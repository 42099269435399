import { useState } from "react";
import axios from "axios";
import { start_url_fetch } from "../module/settings";
import {
  event_list_sort,
  event_list_sort__dataLayer,
  event_list_sort_color__dataLayer,
  event_list_sort_price__dataLayer,
  list_sort_data_layer,
} from "../module/dataLayer";

export const Tools = (options) => {
  const step = ["Prix : ", "Couleurs :"];
  const lst_color = ["#B3CAC2", "#E2B0E6", "#B0E6B9", "#CFE8EC"];
  const lst_price = ["Min :", "Max :"];
  const [value, set_value] = useState([0, 1000]);
  const [actual_color, set_actual_color] = useState(null);
  const cat = window.location.href.split("?")[1];
  const page_cat =
    parseInt(cat) === 0
      ? "Tee shirt"
      : parseInt(cat) === 1
      ? "hoodie"
      : parseInt(cat) === 2
      ? "cap"
      : "all";
  return (
    <div className={"tools"}>
      {step.map((a, i) => (
        <div className={"container-step"}>
          <div className={"title"}>{a}</div>
          {i === 1 ? (
            <div className={"container-color"}>
              {lst_color.map((a, i) => (
                <div
                  className={"color"}
                  onClick={() => {
                    const this_color_for_data_layer =
                      i === 0
                        ? "grey"
                        : i === 1
                        ? "pink"
                        : i === 2
                        ? "green"
                        : "blue";
                    event_list_sort__dataLayer(
                      page_cat,
                      "color",
                      this_color_for_data_layer
                    );
                    cat === undefined
                      ? axios(
                          start_url_fetch +
                            "/get_product_by_tools?color=" +
                            i +
                            "&min=" +
                            document.querySelector(".price1").value +
                            "&max=" +
                            document.querySelector(".price2").value +
                            "&cat=" +
                            cat
                        ).then((r) => {
                          let data = [];
                          for (
                            let i = 0;
                            i < r.data.split(";").length - 1;
                            i++
                          ) {
                            data.push([r.data.split(";")[i].split(",")]);
                          }
                          options["set"](data);
                        })
                      : axios(
                          start_url_fetch +
                            "/get_product_by_tools_3?color=" +
                            i +
                            "&min=" +
                            document.querySelector(".price1").value +
                            "&max=" +
                            document.querySelector(".price2").value +
                            "&cat=" +
                            cat
                        ).then((r) => {
                          let data = [];
                          for (
                            let i = 0;
                            i < r.data.split(";").length - 1;
                            i++
                          ) {
                            data.push([r.data.split(";")[i].split(",")]);
                          }
                          options["set"](data);
                        });
                    set_actual_color(i);
                  }}
                  style={{ "--clr": a }}
                ></div>
              ))}
            </div>
          ) : (
            <div>
              {lst_price.map((a, i) => (
                <div className={"container-color"}>
                  <h3>{a}</h3>
                  <input
                    className={"price" + (i + 1)}
                    defaultValue={i === 1 ? 1000 : 0}
                    min={0}
                    max={1000}
                    type={"range"}
                    id={a}
                    onChange={() => {
                      i === 0
                        ? event_list_sort__dataLayer(
                            page_cat,
                            "price_min",
                            document.getElementById(a).value
                          )
                        : event_list_sort__dataLayer(
                            page_cat,
                            "price_max",
                            document.getElementById(a).value
                          );
                      i === 1
                        ? set_value([
                            value[0],
                            document.getElementById(a).value,
                          ])
                        : set_value([
                            document.getElementById(a).value,
                            value[1],
                          ]);
                      if (
                        document.querySelector(".price1").value >
                        document.querySelector(".price2").value
                      ) {
                        document.querySelector(".price2").value =
                          document.querySelector(".price1").value;
                        set_value([document.getElementById(a).value, value[0]]);
                      }
                      if (
                        document.querySelector(".price2").value <
                        document.querySelector(".price1").value
                      ) {
                        document.querySelector(".price1").value =
                          document.querySelector(".price2").value;
                        set_value([document.getElementById(a).value, value[1]]);
                      }
                      actual_color === null
                        ? cat === undefined
                          ? axios(
                              start_url_fetch +
                                "/get_product_by_tools_1?color=" +
                                actual_color +
                                "&min=" +
                                document.querySelector(".price1").value +
                                "&max=" +
                                document.querySelector(".price2").value +
                                "&cat=" +
                                cat
                            ).then((r) => {
                              let data = [];
                              for (
                                let i = 0;
                                i < r.data.split(";").length - 1;
                                i++
                              ) {
                                data.push([r.data.split(";")[i].split(",")]);
                              }
                              options["set"](data);
                            })
                          : axios(
                              start_url_fetch +
                                "/get_product_by_tools_2?color=" +
                                actual_color +
                                "&min=" +
                                document.querySelector(".price1").value +
                                "&max=" +
                                document.querySelector(".price2").value +
                                "&cat=" +
                                cat
                            ).then((r) => {
                              let data = [];
                              for (
                                let i = 0;
                                i < r.data.split(";").length - 1;
                                i++
                              ) {
                                data.push([r.data.split(";")[i].split(",")]);
                              }
                              options["set"](data);
                            })
                        : cat === undefined
                        ? axios(
                            start_url_fetch +
                              "/get_product_by_tools?color=" +
                              actual_color +
                              "&min=" +
                              document.querySelector(".price1").value +
                              "&max=" +
                              document.querySelector(".price2").value +
                              "&cat=" +
                              cat
                          ).then((r) => {
                            let data = [];
                            for (
                              let i = 0;
                              i < r.data.split(";").length - 1;
                              i++
                            ) {
                              data.push([r.data.split(";")[i].split(",")]);
                            }
                            options["set"](data);
                          })
                        : axios(
                            start_url_fetch +
                              "/get_product_by_tools_3?color=" +
                              actual_color +
                              "&min=" +
                              document.querySelector(".price1").value +
                              "&max=" +
                              document.querySelector(".price2").value +
                              "&cat=" +
                              cat
                          ).then((r) => {
                            let data = [];
                            for (
                              let i = 0;
                              i < r.data.split(";").length - 1;
                              i++
                            ) {
                              data.push([r.data.split(";")[i].split(",")]);
                            }
                            options["set"](data);
                          });
                    }}
                  />
                  <span className={"price"}>{value[i]}€</span>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
