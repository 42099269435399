export const Array = (data) => {
  console.log(data);
  return (
    <table>
      <thead>
        <tr>
          {data["head"].map((a) => (
            <th>{a}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data["body"].map((a) => (
          <tr>
            {a.map((b) => (
              <th>{b}</th>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
